<template>
  <div class="space-y-4">
	<div>
	  <h2 class="text-xl font-medium">{{ $t('pages.landing.slide_one.title') }}</h2>

	  <h3 class="text-lg">
		{{ $t('pages.landing.slide_one.sub_title') }}
	  </h3>
	</div>

	<div class="py-4">
	  <img :src="asset('img/14.5.svg')" class="w-1/3 mx-auto"/>
	</div>

	<p class="text-lg">{{ $t('pages.landing.slide_one.body_1') }}</p>

	<p class="text-lg font-semibold">{{ $t('pages.landing.slide_one.body_2') }}</p>

	<ul class="text-lg list-disc ml-6">
	  <li>{{ $t('pages.landing.slide_one.list_1') }}</li>
	  <li>{{ $t('pages.landing.slide_one.list_2') }}</li>
	  <li>{{ $t('pages.landing.slide_one.list_3') }}</li>
	  <li>{{ $t('pages.landing.slide_one.list_4') }}</li>
	</ul>
  </div>
</template>

<script>
import SlideFooter from "../SlideFooter.vue";

export default {
  components: {
	SlideFooter
  }
}
</script>
