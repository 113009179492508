<template>
    <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.09 9.76611C9.3251 9.09778 9.78915 8.53422 10.4 8.17525C11.0108 7.81627 11.7289 7.68505 12.4272 7.80483C13.1255 7.9246 13.7588 8.28764 14.2151 8.82964C14.6713 9.37164 14.9211 10.0576 14.92 10.7661C14.92 12.7661 11.92 13.7661 11.92 13.7661M12 17.7661H12.01M22 12.7661C22 18.289 17.5228 22.7661 12 22.7661C6.47715 22.7661 2 18.289 2 12.7661C2 7.24327 6.47715 2.76611 12 2.76611C17.5228 2.76611 22 7.24327 22 12.7661Z"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {}
</script>