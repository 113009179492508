import {defineStore} from "pinia/dist/pinia";

export const useToastStore = defineStore('toast', {
    state: () => (
        {
            toasts: []
        }
    ),
    actions: {
        addToast(message) {
            let toast = {
                id: Math.random().toString(36).substr(2, 9),
                ...message
            };

            if (this.toasts.find(toast => toast.message === message.message)) {
                return;
            }

            this.toasts.push(toast);

            setTimeout(() => {
                this.removeToast(toast.id);
            }, 5000);
        },
        success(message) {
            this.addToast({
                message: message,
                type: 'success'
            })
        },
        error(message) {
            this.addToast({
                message: message,
                type: 'error'
            })
        },
        warning(message) {
            this.addToast({
                message: message,
                type: 'warning'
            })
        },
        info(message) {
            this.addToast({
                message: message,
                type: 'info'
            })
        },
        removeToast(id) {
            this.toasts = this.toasts.filter(toast => toast.id !== id);
        }
    }
});