<template>
  <PageLayout
	  v-if="$route.meta.auth === true && $route.meta.useLayout !== false"
	  :title="title"
  >
	<RouterView
		:key="authStore.authUser.current_team?.id"
		@renderCookieBanner="renderCookieBanner"
	/>
  </PageLayout>

  <RouterView v-else/>

  <CookieConsent
	  :key="renderKey"
  />
</template>

<script>
import PageLayout from "./components/layout/PageLayout.vue";
import {useAuthStore} from "./store/auth.js";
import CookieConsent from "./components/CookieConsent.vue";
import LandingInformationModal from "./components/landing/LandingInformationModal.vue";
import DailyQuestionModal from "./components/daily_question/DailyQuestionModal.vue";

export default {
  setup() {
	const authStore = useAuthStore();

	return {
	  authStore
	}
  },
  components: {
	DailyQuestionModal,
	LandingInformationModal,
	CookieConsent,
	PageLayout
  },
  data() {
	return {
	  title: null,
	  renderKey: 0
	}
  },
  methods: {
	renderCookieBanner() {
	  this.renderKey = this.renderKey + 1;
	}
  },
  computed: {
	shouldHidePopup() {
	  return ['assessment.start', 'assessment.step', 'assessment.psych-safety', 'assessment.done', 'assessment.step.divider'].includes(this.$route.name);
	}
  }
}
</script>
