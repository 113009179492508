<template>
  <RouterLink
	  :to="{
		name: this.to
	  }"
	  :class="[
		  this.activeClasses,
		  this.mobile ? 'block border-l-4 py-2 pl-3 pr-4 text-base ' : 'inline-flex items-center px-1 pt-1 text-sm font-medium'
	  ]"
  >
	<slot/>
  </RouterLink>
</template>
<script>
export default {
  props: {
	active: {
	  type: Boolean,
	  default: false
	},
	to: {
	  type: String,
	  required: true
	},
	mobile: {
	  type: Boolean,
	  default: false
	}
  },
  computed: {
	activeClasses() {
	  if (this.active && this.mobile) {
		return "font-medium text-blue-700 border-blue-500 bg-blue-50";
	  }

	  if (this.active) {
		return "text-blue-500";
	  }

	  if (this.mobile) {
		return "text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 border-transparent";
	  }

	  return "text-gray-500 hover:border-gray-300 hover:text-gray-700";
	}
  }
}
</script>