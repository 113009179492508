<template>
    <div
        class="rounded-lg bg-white p-4 border"
        :class="[
            border,
            'shadow-' + this.shadow
        ]"
    >
        <div class="flex">
            <div class="hidden flex-shrink-0 md:flex">
                <div
                    class="h-14 w-14 rounded-full flex items-center justify-center shadow"
                    :class="[
                        'bg-' + this.iconColour + '-50',
                    ]"
                >
                    <div
                        class="h-10 w-10 rounded-full flex items-center justify-center"
                        :class="[
                            'bg-' + this.iconColour + '-100',
                            'text-' + this.iconColour + '-500',
                        ]"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                             stroke="currentColor" class="w-7 h-7">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="md:ml-4 flex-1 md:flex md:justify-between md:items-center">
                <div>
                    <slot/>
                </div>
                <p class="mt-3 text-sm md:mt-0 md:ml-6 flex items-center" v-if="buttonText">
                    <BaseButton
                        :title="buttonText"
                        @click="$emit('submit')"
                    />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "./lib/input/BaseButton.vue";

export default {
    props: {
        buttonText: {
            type: String,
            required: false
        },
        colour: {
            type: String,
            required: false,
            default: 'primary'
        },
        shadow: {
            type: String,
            required: false,
            default: 'md'
        }
    },
    components: {
        BaseButton
    },
    computed: {
        border() {
            switch (this.colour) {
                case 'primary':
                    return 'border-[#84adff]'
                default:
                    return '';
            }
        },
        iconColour() {
            switch (this.colour) {
                case 'primary':
                    return 'blue'
                default:
                    return 'gray';
            }
        }
    }
}
</script>