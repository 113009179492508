<template>
  <TransitionRoot as="template" :show="open">
	<Dialog as="div" class="relative z-10" @close="$emit('close')">
	  <TransitionChild
		  as="template"
		  enter="ease-out duration-300"
		  enter-from="opacity-0"
		  enter-to="opacity-100"
		  leave="ease-in duration-200"
		  leave-from="opacity-100"
		  leave-to="opacity-0"
	  >
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
	  </TransitionChild>

	  <div class="fixed inset-0 z-10 overflow-y-auto">
		<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
		  <TransitionChild
			  as="template"
			  enter="ease-out duration-300"
			  enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			  enter-to="opacity-100 translate-y-0 sm:scale-100"
			  leave="ease-in duration-200"
			  leave-from="opacity-100 translate-y-0 sm:scale-100"
			  leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  >
			<DialogPanel
				class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full"
				:class="[
								sizeClass
							]"
			>
			  <div
				  :class="{
									'px-4 pt-5 pb-4 sm:p-6 sm:pb-4': ! hideTitle,
									'px-4 pt-5 pb-4 sm:p-6': hideTitle,
								}"
			  >
				<div class="mt-3 sm:mt-0 text-left">
				  <div
					  class="flex items-center"
					  v-if="! hideTitle"
				  >
					<component v-if="icon" :is="icon" class="rounded-lg"/>
					<DialogTitle
						as="h3"
						:class="[
											  titleClass, icon ? 'ml-4' : ''
										  ]"
					>{{ title }}
					</DialogTitle>
				  </div>
				  <div
					  :class="{
										  'mt-6': ! hideTitle
										}"
				  >
					<slot/>
				  </div>
				</div>
			  </div>
			  <div
				  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 bottom-0 space-y-2 sm:space-y-0"
				  v-if="! hideSubmit || ! hideCancel"
			  >
				<BaseButton
					v-if="!hideSubmit"
					:title="submitText"
					:loading="submitLoading"
					@click="$emit('submit')"
					class="sm:ml-2"
					:colour="submitColour"
					:modal="true"
					:disabled="submitDisabled"
				/>
				<button
					v-if="!hideCancel"
					type="button"
					class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-theme-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
					@click="$emit('close')"
					ref="cancelButtonRef">
				  {{ cancelText }}
				</button>
			  </div>
			</DialogPanel>
		  </TransitionChild>
		</div>
	  </div>
	</Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {CheckIcon} from '@heroicons/vue/24/outline'
import BaseButton from "./input/BaseButton.vue";

export default {
  emits: [
	'close',
	'submit'
  ],
  props: {
	open: {
	  type: Boolean,
	  required: true
	},
	icon: {
	  type: Object,
	  required: false
	},
	title: {
	  type: String,
	  required: false
	},
	submitText: {
	  type: String,
	  default: "Submit"
	},
	submitColour: {
	  type: String,
	  default: 'primary'
	},
	submitLoading: {
	  type: Boolean,
	  default: false
	},
	hideSubmit: {
	  type: Boolean,
	  required: false,
	  default: false
	},
	hideCancel: {
	  type: Boolean,
	  required: false,
	  default: false
	},
	cancelText: {
	  type: String,
	  default: "Cancel"
	},
	size: {
	  type: String,
	  default: 'md',
	  validator: function (value) {
		return ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'].indexOf(value) !== -1
	  }
	},
	hideTitle: {
	  type: Boolean,
	  default: false
	},
	titleClass: {
	  type: Array,
	  default: ['text-lg font-medium leading-6 text-gray-900']
	},
	submitDisabled: {
	  type: Boolean,
	  default: false
	}
  },
  components: {
	BaseButton,
	Dialog,
	DialogPanel,
	DialogTitle,
	TransitionChild,
	TransitionRoot,
	CheckIcon
  },
  computed: {
	sizeClass() {
	  return {
		'sm:max-w-sm': this.size === 'sm',
		'sm:max-w-lg': this.size === 'md',
		'sm:max-w-2xl': this.size === 'lg',
		'sm:max-w-3xl': this.size === 'xl',
		'sm:max-w-4xl': this.size === '2xl',
		'sm:max-w-5xl': this.size === '3xl',
		'sm:max-w-6xl': this.size === '4xl',
	  }
	}
  }
}
</script>
