import {createRouter, createWebHistory} from 'vue-router';

import authRoutes from "./auth"
import dashboardRoutes from "./dashboard"
import assessmentRoutes from "./assessment"
import challengeRoutes from "./challenges"
import resultsRoutes from "./results"
import organisationsRoutes from "./organisations"
import teamRoutes from "./teams"
import announcements from "./announcements.js";

import {useAuthStore} from "../store/auth.js";
import middlewarePipeline from "./middlewarePipeline.js";
import onboarding from "./onboarding.js";
import guide from "./guide.js";
import PageNotFound from "../views/auth/PageNotFound.vue";
import auth from "../middleware/auth.js";

let routes = [
    ...authRoutes,
    ...dashboardRoutes,
    ...assessmentRoutes,
    ...challengeRoutes,
    ...resultsRoutes,
    ...onboarding,
    ...guide,
    ...organisationsRoutes,
    ...teamRoutes,
    ...announcements,
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        name: 'not-found',
        meta: {
            title: 'Perspective - Not found',
            hideTeamName: true,
            middleware: [auth],
            auth: true
        }
    },
]

let router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        window.scrollTo(0, 0);
    }
});

router.beforeEach((to, from, next) => {

    const store = useAuthStore();

    const middleware = to.meta.middleware;
    const context = {to, from, next, store, router};

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});


export default router;
