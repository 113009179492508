<template>
  <div class="space-y-4 fixed right-6 bottom-6 flex flex-col z-[1199]">
	<TransitionGroup
		enter-active-class="v-toast--fade-in-up"
		leave-active-class="v-toast--fade-out"
	>
	  <div
		  class="rounded-md p-4 item block"
		  :class="[
			  colours[toast.type],
		  ]"
		  v-for="(toast, index) in toastStore.toasts"
		  v-if="toastStore.toasts && toastStore.toasts.length > 0"
	  >
		<div class="flex justify-between flex-1 max-w-[20rem]">
		  <div class="flex">
			<div class="flex-shrink-0 text-white">
			  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" v-if="toast.type === 'error' || toast.type === 'warning'">
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
			  </svg>
			  <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" v-if="toast.type === 'success'">
				<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
			  </svg>
			  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" v-if="toast.type === 'info'">
				<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
			  </svg>
			</div>
			<div class="ml-3 flex items-center">
			  <p class="text-sm font-medium text-white">{{ toast.message }}</p>
			</div>
		  </div>
		  <div class="pl-4 flex items-center">
			<div class="-mx-1.5 -my-1.5">
			  <button
				  @click="toastStore.removeToast(toast.id)"
				  type="button"
				  class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 text-white"
			  >
				<span class="sr-only">Dismiss</span>
				<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
				  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
				</svg>
			  </button>
			</div>
		  </div>
		</div>
	  </div>
	</TransitionGroup>
  </div>
</template>

<script>
import {useToastStore} from "../../store/toast.js";

export default {
  setup() {
	const toastStore = useToastStore();

	return {
	  toastStore
	}
  },
  props: {
	show: {
	  type: Boolean,
	  default: false
	}
  },
  data() {
	return {
	  colours: {
		success: 'bg-green-500',
		error: 'bg-red-500',
		warning: 'bg-yellow-500',
		info: 'bg-blue-500'
	  }
	}
  },
  methods: {

  }
}
</script>

<style scoped>
.item {
  display: inline-flex;
  align-items: center;
  animation-duration: 300ms;
}

@keyframes fadeOut {
  from {
	opacity: 1;
  }
  to {
	opacity: 0;
  }
}

.v-toast--fade-out {
  animation-name: fadeOut;
}

@keyframes fadeInDown {
  from {
	opacity: 0;
	transform: translate3d(0, -100%, 0);
  }
  to {
	opacity: 1;
	transform: none;
  }
}

.v-toast--fade-in-down {
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
	opacity: 0;
	transform: translate3d(0, 100%, 0);
  }
  to {
	opacity: 1;
	transform: none;
  }
}

.v-toast--fade-in-up {
  animation-name: fadeInUp;
}

/**
 * Vue Transitions
 */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>