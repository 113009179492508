<template>
  <div class="col-span-3 bg-white rounded-lg p-4 md:p-6 shadow">
	<div
		:class="{
                'border-b border-gray-200': border,
                'pb-3': (!this.$slots.aside || this.forcePadding) && !disablePadding
		    }"
		class="flex items-center justify-between"
		v-if="title"
	>
	  <div
		  class="md:flex w-full"
		  :class="[
			  justifyClass
		  ]"
	  >
		<div class="flex items-center">
		  <div>
			<h3 class="text-base font-medium leading-6 text-gray-900">
			  {{ title }}
			  <slot name="inline"/>
			</h3>
			<slot name="subtitle"/>
		  </div>
		  <slot name="title"/>
		</div>
		<slot name="aside"/>
	  </div>
	</div>
	<div class="flex-col">
	  <slot name="actions"/>
	</div>
	<div
		:class="[
			this.marginTop ? 'mt-4' : ''
		]"
	>
	  <slot/>
	</div>
  </div>
</template>

<script>
export default {
  props: {
	title: {
	  type: String,
	  required: false
	},
	border: {
	  type: Boolean,
	  default: true
	},
	marginTop: {
	  type: Boolean,
	  default: true
	},
	forcePadding: {
	  type: Boolean,
	  default: false
	},
	disablePadding: {
	  type: Boolean,
	  default: false
	},
	justifyClass: {
	  type: String,
	  default: 'justify-between'
	}
  }
}
</script>