import auth from "../middleware/auth.js";
import mustCompleteAssessment from "../middleware/mustCompleteAssessment.js";

export default [
    {
        path: "/announcements",
        name: 'announcements.index',
        component: () => import("../views/auth/announcements/Index.vue"),
        meta: {
            hideTeamName: true,
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.announcements',
            auth: true
        }
    },
    {
        path: "/announcements/create",
        name: 'announcements.create',
        component: () => import("../views/auth/announcements/Create.vue"),
        meta: {
            hideTeamName: true,
            middleware: [auth],
            heading: 'What\'s new in Perspective - Add new',
            auth: true
        }
    },
    {
        path: "/announcements/:id/edit",
        name: 'announcements.edit',
        component: () => import("../views/auth/announcements/Create.vue"),
        meta: {
            hideTeamName: true,
            middleware: [auth],
            heading: 'What\'s new in Perspective - Edit',
            auth: true
        }
    }
]