<template>
  <div class="flex-1">
	<div class="sm:hidden">
	  <select
		  id="tabs" name="tabs"
		  class="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
		  @change="this.$router.push({name: $event.target.value})"
	  >
		<option v-for="tab in this.tabs" :key="tab.name" :value="tab.route">{{ tab.name }}</option>
	  </select>
	</div>
	<div class="hidden sm:block">
	  <nav class="flex space-x-4 -px-3" aria-label="Tabs">
		<RouterLink
			v-for="tab in this.tabs"
			:to="{
			  name: tab.route
			}"
			class="text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium"
			active-class="!text-blue-500"
		>
		  {{ tab.name }}
		</RouterLink>
	  </nav>
	</div>
  </div>
</template>

<script>
export default {
  props: {
	isOrganisationView: {
	  default: false,
	}
  },
  computed: {
	tabs() {
	  if (this.isOrganisationView) {
		return [
		  {
			name: this.$t('other.navigation.overview'),
			route: 'results.organisation.index'
		  },
		  {
			name: this.$t('other.navigation.team_scores'),
			route: 'results.organisation.pq-scores',
		  }
		]
	  } else {
		return [
		  {
			name: this.$t('other.navigation.overview'),
			route: 'results.index'
		  },
		  {
			name: this.$t('other.navigation.team_scores'),
			route: 'results.team-scores'
		  }
		]
	  }
	}
  }
}
</script>