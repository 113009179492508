<template>
  <BaseModal
	  :open="open"
	  :hide-title="true"
	  :hide-submit="true"
	  :hide-cancel="true"
  >
	<div
		class="text-gray-600"
		v-if="!hasWellbeingAnswered"
	>
	  {{ $t('common.terms.hi') }} {{ this.authStore.authUser.first_name || this.authStore.authUser.name }}.
	  <span class="font-semibold">{{ $t('pages.daily_question_modal.how_are_you_feeling_today') }}</span>
	</div>

	<div class="space-y-1 mt-3">
	  <div
		  v-if="!hasWellbeingAnswered"
		  class="border rounded-lg"
		  v-for="question in this.wellbeingQuestions"
	  >
		<button
			class="flex py-2.5 items-center px-4 w-full"
			@click="wellbeingAnswer = question.score"
		>
		  <img :src="asset('img/survey/' + question.score + '.svg')">

		  <span class="ml-2">{{ question.text }}</span>
		</button>
	  </div>
	  <div v-if="hasWellbeingAnswered" class="py-16 px-6 text-center flex flex-col">
		{{ this.WELLBEING_QUESTION_TEXT[this.wellbeingAnswer] }}
		<div class="mt-4">
		  <BaseButton
			  :title="$t('common.buttons.continue')"
			  @click="handleSubmitDailyQuestion"
		  />
		</div>
	  </div>
	</div>
  </BaseModal>
</template>

<script>
import BaseModal from "../lib/BaseModal.vue";
import {useAuthStore} from "../../store/auth.js";
import BaseButton from "../lib/input/BaseButton.vue";
import DailyQuestionService from "../../api/DailyQuestionService.js";

export default {
  props: {
	open: {
	  type: Boolean,
	  required: true
	}
  },
  setup() {
	const authStore = useAuthStore();

	return {
	  authStore
	}
  },
  components: {
	BaseButton,
	BaseModal
  },
  data() {
	return {
	  screen: 'how-are-you-feeling',
	  wellbeingQuestions: [
		{
		  score: 5,
		  text: this.$t('pages.daily_question_modal.answers.flourishing')
		},
		{
		  score: 4,
		  text: this.$t('pages.daily_question_modal.answers.really_good')
		},
		{
		  score: 3,
		  text: this.$t('pages.daily_question_modal.answers.im_okay')
		},
		{
		  score: 2,
		  text: this.$t('pages.daily_question_modal.answers.im_stressed')
		},
		{
		  score: 1,
		  text: this.$t('pages.daily_question_modal.answers.im_overwhelmed')
		}
	  ],
	  wellbeingAnswer: null,
	  engagementAnswer: null,
	  WELLBEING_QUESTION_TEXT: {
		5: this.$t('pages.daily_question_modal.wellbeing_responses.five'),
		4: this.$t('pages.daily_question_modal.wellbeing_responses.four'),
		3: this.$t('pages.daily_question_modal.wellbeing_responses.three'),
		2: this.$t('pages.daily_question_modal.wellbeing_responses.two'),
		1: this.$t('pages.daily_question_modal.wellbeing_responses.one'),
	  },
	  loading: false
	}
  },
  computed: {
	hasWellbeingAnswered() {
	  return this.wellbeingAnswer !== null;
	}
  },
  methods: {
	handleSubmitDailyQuestion() {
	  this.loading = true;

	  DailyQuestionService.postDailyQuestion({
		wellbeing: this.wellbeingAnswer
	  }).then(() => {
		this.loading = false;
		this.authStore.authUser.daily_question = false;

		this.$emit('close')
	  })
	}
  }
}
</script>

<style scoped>

</style>