import Team from "./Team.js";

export default class Organisation {
    constructor(data = null) {
        if (!data) return;

        this.id = data.id;
        this.name = data.name;
        this.teams_count = data.teams_count;
        this.users_count = data.users_count;
        this.teams = data.teams ? data.teams.map(team => new Team(team)) : null;
        this.sector = data.sector;
        this.size = data.size;
        this.assessment_version = data.assessment_version;
        this.created_at = data.created_at;
    }
}