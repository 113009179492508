import auth from "../middleware/auth.js";
import manageGuide from "../middleware/manageGuide.js";

export default [
    {
        path: "/guide",
        name: 'guide.index',
        component: () => import("../views/auth/guide/Index.vue"),
        meta: {
            middleware: [auth],
            hideTeamName: true,
            heading: 'headings.guide',
            auth: true
        },
        children: [
            {
                path: "/guide/create",
                name: 'guide.create',
                meta: {
                    middleware: [auth, manageGuide],
                },
                component: () => import("../views/auth/guide/admin/Create.vue"),
            },
            {
                path: "/guide/:slug",
                name: 'guide.content',
                middleware: [auth],
                component: () => import("../views/auth/guide/Show.vue"),
            },
            {
                path: "/guide/:slug/create",
                name: 'guide.content.create',
                meta: {
                    middleware: [auth, manageGuide],
                },
                component: () => import("../views/auth/guide/admin/Create.vue"),
            },
            {
                path: "/guide/:slug/edit",
                name: 'guide.edit',
                meta: {
                    middleware: [auth, manageGuide],
                },
                component: () => import("../views/auth/guide/admin/Create.vue"),
            },
        ]
    },
    {
        path: "/guide/what-is",
        name: 'guide.what-is',
        redirect: "/",
        component: () => import("../views/auth/guide/WhatIs.vue"),
        meta: {
            middleware: [auth],
            hideTeamName: true,
            heading: 'headings.guide',
            auth: true
        }
    },
    {
        path: "/guide/raw-assessment",
        name: 'guide.raw-assessment',
        redirect: "/",
        component: () => import("../views/auth/guide/RawAssessment.vue"),
        meta: {
            middleware: [auth],
            hideTeamName: true,
            heading: 'headings.guide',
            auth: true
        }
    },
    {
        path: "/guide/success",
        name: 'guide.success',
        redirect: "/",
        component: () => import("../views/auth/guide/Success.vue"),
        meta: {
            middleware: [auth],
            hideTeamName: true,
            heading: 'headings.guide',
            auth: true
        }
    }
]