export default function guest({ next, store }) {
    if (store.authUser) {
        next({ name: "home" });
    } else {
        store.getMe().then((user) => {
            next({ name: "home" });
        }).catch(() => {
            next();
        });
    }
}
