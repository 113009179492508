import * as Sentry from "@sentry/vue";

export default function auth({to, next, store, router}) {
    const loginQuery = {path: "/login", query: {redirect: to.fullPath}};

    if (!store.authUser) {
        store.getMe().then((response) => {
            if (!store.authUser) {
                return router.push(loginQuery);
            } else {
                next();
            }
        }).catch((response) => {
            return router.push(loginQuery);
        })
    } else {
        try {
            Sentry.setUser({
                id: store.authUser?.id,
            });
        } catch (e) {
            console.log(e);
        }

        // Determine if there is a redirect
        next();
    }
}
