import auth from "../middleware/auth.js";

export default [
    {
        path: "/profile",
        name: 'profile',
        component: () => import("../views/auth/onboarding/Onboarding.vue"),
        meta: {
            useLayout: false,
            hideTeamName: true,
            middleware: [auth],
            auth: true
        }
    }
]
