<template>
  <BaseModal
	  :open="true"
	  :hide-submit="true"
	  :hide-cancel="true"
	  :hide-title="true"
	  size="xl"
  >
	<div>
	  <TransitionGroup
		  name="fade"
		  mode="out-in"
		  tag="div"
	  >
		<component
			v-for="(slide, idx) in slides"
			:key="idx"
			:is="slide.component"
			@next="nextSlide"
			@previous="previousSlide"
			v-show="currentSlide === idx"
		/>
	  </TransitionGroup>
	</div>

	<SlideFooter @next="nextSlide" :is-last="isLastSlide"/>
  </BaseModal>
</template>

<script>
import BaseModal from "../lib/BaseModal.vue";
import SlideOne from "./content/SlideOne.vue";
import SlideTwo from "./content/SlideTwo.vue";
import SlideThree from "./content/SlideThree.vue";
import SlideFour from "./content/SlideFour.vue";
import SlideFooter from "./SlideFooter.vue";
import {markRaw} from "vue";
import AuthService from "../../api/AuthService.js";
import {useAuthStore} from "../../store/auth.js";

export default {
  components: {SlideFooter, SlideFour, SlideThree, SlideTwo, SlideOne, BaseModal},
  setup() {
	const authStore = useAuthStore();

	return {
	  authStore
	}
  },
  data() {
	return {
	  currentSlide: 0,
	  slides: [
		{
		  component: markRaw(SlideOne),
		},
		{
		  component: markRaw(SlideTwo),
		},
		{
		  component: markRaw(SlideThree),
		},
		{
		  component: markRaw(SlideFour),
		}
	  ]
	}
  },
  computed: {
	isLastSlide() {
	  return this.currentSlide === this.slides.length - 1;
	}
  },
  methods: {
	nextSlide() {
	  if (this.isLastSlide) {
		this.authStore.authUser.should_see_welcome_popup = false;

		AuthService.putAcknowledgeWelcome();
	  } else {
		this.currentSlide++;
	  }
	},
	previousSlide() {
	  this.currentSlide--;
	}
  }
}
</script>

<style>
.fade-enter-active {
  transition: opacity 0.8s ease;
}

.fade-leave-active {
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>