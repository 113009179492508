import auth from "../middleware/auth.js";
import mustCompleteAssessment from "../middleware/mustCompleteAssessment.js";

export default [
    {
        path: "/",
        name: 'home',
        component: () => import("../views/auth/Home.vue"),
        meta: {
            title: 'Perspective - Home',
            heading: 'Dashboard',
            hideTeamName: true,
            middleware: [auth, mustCompleteAssessment],
            auth: true
        }
    },
    {
        path: "/activity",
        redirect: "/",
    }
]
