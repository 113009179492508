import apiClient from './base'

export default {
    async postSwitchTeam(teamId) {
        return apiClient.post(`/teams/${teamId}/switch`);
    },

    async postUpdateProfile(data) {
        return apiClient.post(`/account/update-profile`, data);
    },

    async getNotifications() {
        return apiClient.get(`/notifications`);
    }
}