import guest from "../middleware/guest.js";

export default [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        name: 'authentication.login',
        component: () => import("../views/guest/Login.vue"),
        meta: {
            title: 'Perspective - Login',
            middleware: [guest],
            auth: false
        }
    },
    {
        path: "/forgot-password",
        name: 'authentication.forgot-password',
        component: () => import("../views/guest/ForgotPassword.vue"),
        meta: {
            title: 'Perspective - Forgot password',
            middleware: [guest],
            auth: false
        }
    },
    {
        path: "/reset-password",
        name: 'authentication.reset-password',
        component: () => import("../views/guest/SetPassword.vue"),
        meta: {
            title: 'Perspective - Set password',
            middleware: [guest],
            auth: false
        }
    },
    {
        path: "/welcome",
        name: 'authentication.welcome',
        component: () => import("../views/guest/Welcome.vue"),
        meta: {
            title: 'Welcome to Perspective',
            middleware: [guest],
            auth: false
        }
    },
]
