import auth from "../middleware/auth.js";
import mustCompleteAssessment from "../middleware/mustCompleteAssessment.js";

export default [
    {
        path: "/challenges",
        name: 'challenges.index',
        component: () => import("../views/auth/challenges/Index.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.challenges',
            auth: true
        }
    },
    {
        path: "/challenges/create",
        name: 'challenges.create',
        component: () => import("../views/auth/challenges/Create.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.create_challenges',
            auth: true
        }
    },
    {
        path: "/challenges/create/team/:teamId",
        name: 'challenges.create.team',
        component: () => import("../views/auth/challenges/Create.vue"),
        meta: {
            middleware: [auth],
            heading: 'headings.create_challenges',
            auth: true
        }
    },
    {
        path: "/challenges/:id/preview",
        name: 'challenges.preview',
        component: () => import("../views/auth/challenges/Show.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.team_challenge',
            auth: true
        }
    },
    {
        path: "/challenges/:id",
        name: 'challenges.show',
        component: () => import("../views/auth/challenges/Show.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.team_challenge',
            auth: true
        }
    },
    {
        path: "/challenges/vote",
        name: 'challenges.vote',
        component: () => import("../views/auth/challenges/Vote.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.vote_on_challenges',
            auth: true
        }
    },
    {
        path: "/challenges/:id/meeting-review",
        name: 'challenges.meeting-review',
        component: () => import("../views/auth/challenges/meeting_review/MeetingReview.vue"),
        meta: {
            useLayout: false,
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.team_challenge',
            auth: true
        }
    },
    {
        path: "/challenges/:id/session/",
        component: () => import("../views/auth/challenges/session/Index.vue"),
        meta: {
            middleware: [auth, mustCompleteAssessment],
            heading: 'headings.challenge_session',
            auth: true
        },
        children: [
            {
                path: 'check-in',
                name: 'session.check-in',
                component: () => import("../views/auth/challenges/session/CheckIn.vue"),
            },
            {
                path: 'previous-challenge',
                name: 'session.previous-challenge',
                component: () => import("../views/auth/challenges/session/PreviousChallenge.vue"),
            },
            {
                path: 'prioritised-challenge',
                name: 'session.prioritised-challenge',
                component: () => import("../views/auth/challenges/session/PrioritisedChallenge.vue"),
            },
            {
                path: 'driving-prioritised-challenge',
                name: 'session.driving-prioritised-challenge',
                component: () => import("../views/auth/challenges/session/WhatsDrivingChallenge.vue"),
            },
            {
                path: 'team-capabilities',
                name: 'session.team-capabilities',
                component: () => import("../views/auth/challenges/session/TeamCapabilities.vue"),
            },
            {
                path: 'actions',
                name: 'session.actions',
                component: () => import("../views/auth/challenges/session/Actions.vue"),
            },
            {
                path: 'summary',
                name: 'session.summary',
                component: () => import("../views/auth/challenges/session/Summary.vue"),
            },
            {
                path: 'preview/check-in',
                name: 'session.preview.check-in',
                component: () => import("../views/auth/challenges/session/CheckIn.vue"),
            },
            {
                path: 'preview/previous-challenge',
                name: 'session.preview.previous-challenge',
                component: () => import("../views/auth/challenges/session/PreviousChallenge.vue"),
            },
            {
                path: 'preview/prioritised-challenge',
                name: 'session.preview.prioritised-challenge',
                component: () => import("../views/auth/challenges/session/PrioritisedChallenge.vue"),
            },
            {
                path: 'preview/driving-prioritised-challenge',
                name: 'session.preview.driving-prioritised-challenge',
                component: () => import("../views/auth/challenges/session/WhatsDrivingChallenge.vue"),
            },
            {
                path: 'preview/team-capabilities',
                name: 'session.preview.team-capabilities',
                component: () => import("../views/auth/challenges/session/TeamCapabilities.vue"),
            },
            {
                path: 'preview/actions',
                name: 'session.preview.actions',
                component: () => import("../views/auth/challenges/session/Actions.vue"),
            },
            {
                path: 'preview/summary',
                name: 'session.preview.summary',
                component: () => import("../views/auth/challenges/session/Summary.vue"),
            },
        ]
    }
]