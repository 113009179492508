<template>
  <button
	  :type="type"
	  class="inline-flex items-center gap-x-1.5 rounded-md font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:cursor-not-allowed"
	  :class="[
		  sizeClass,
		  colourClass,
		  loading || disabled ? 'opacity-50' : '',
		  modal ? 'w-full sm:w-auto sm:text-sm justify-center' : 'text-sm'
	  ]"
	  :disabled="loading || disabled"
  >
	<slot name="prepend"/>
	{{ title }}
	<slot name="append"/>
	<svg
		class="animate-spin ml-2 h-4 w-4"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		v-if="loading"
		:class="{
		  'text-white' : colour === 'primary',
		  'text-gray-800' : colour === 'secondary'
		}"
	>
	  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
	  <path class="opacity-75" fill="currentColor"
			d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
	</svg>
  </button>
</template>

<script>
export default {
  props: {
	title: {
	  type: String,
	  required: false
	},
	loading: {
	  type: Boolean,
	  required: false,
	  default: false
	},
	type: {
	  type: String,
	  default: 'submit'
	},
	size: {
	  type: String,
	  validator: value => ['xs', 'sm', 'md', 'lg'].includes(value),
	  default: 'md'
	},
	colour: {
	  type: String,
	  validator: value => ['primary', 'secondary', 'danger'].includes(value),
	  default: 'primary'
	},
	disabled: {
	  type: Boolean,
	  required: false
	},
	modal: {
	  type: Boolean,
	  required: false,
	  default: false
	}
  },
  computed: {
	sizeClass() {
	  switch (this.size) {
		case 'xs':
		  return 'py-0.5 px-1.5'
		case 'sm':
		  return 'py-1.5 px-2.5'
		case 'md':
		  return 'py-2 px-3'
		case 'lg':
		  return 'py-2.5 px-3.5'
	  }
	},
	colourClass() {
	  switch (this.colour) {
		case 'primary':
		  return 'bg-blue-600 text-white enabled:hover:bg-blue-500'
		case 'secondary':
		  return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 enabled:hover:bg-gray-50'
		case 'success':
		  return 'bg-green-500 text-white enabled:hover:bg-green-400'
		case 'danger':
		  return 'bg-red-500 text-white ring-1 ring-inset ring-red-300 enabled:hover:bg-red-400'
	  }
	}
  }
}
</script>
