import {DateTime} from "luxon";

export default class AssessmentRequest {
    constructor(data = null) {
        if (!data) return;

        this.id = data.id;
        this.deadline_at = data.deadline_at;
        this.next_assessment_request_at = data.next_assessment_request_at;
        this.assessments_count = data.assessments_count;
        this.created_at = data.created_at;
    }

    inProgress() {
        // Using Luxon, a date/time library, check if deadline_at is in the future
        // TODO - timezone?
        return DateTime.fromISO(this.deadline_at) > DateTime.now()
    }
}