<template>
  <div class="flex justify-end mt-1">
	<a
		@click="$emit('next')"
		class="cursor-pointer flex items-center space-x-1"
	>
	  <span class="text-lg">
		{{ isLast ? $t('pages.landing.common.done') : $t('pages.landing.common.next') }}
	  </span>
	  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3.5 w-3.5">
		<path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
	  </svg>
	</a>
  </div>
</template>
<script>
export default {
  props: {
	isLast: {
	  type: Boolean,
	  default: false
	}
  }
}
</script>
