import auth from "../middleware/auth.js";

export default [
    {
        path: "/assessment",
        name: 'assessment.start',
        component: () => import("../views/auth/assessment/Index.vue"),
        meta: {
            useLayout: false,
            middleware: [auth],
            auth: true
        }
    },
    {
        path: "/assessment/:step",
        name: 'assessment.step',
        component: () => import("../views/auth/assessment/Index.vue"),
        meta: {
            useLayout: false,
            middleware: [auth],
            auth: true
        }
    },
    {
        path: "/assessment/psych-safety",
        name: 'assessment.psych-safety',
        component: () => import("../views/auth/assessment/Index.vue"),
        meta: {
            useLayout: false,
            middleware: [auth],
            auth: true
        }
    },
    {
        path: "/assessment/done",
        name: 'assessment.done',
        component: () => import("../views/auth/assessment/Index.vue"),
        meta: {
            useLayout: false,
            middleware: [auth],
            auth: true
        }
    },
    {
        path: "/assessment/:step/divider",
        name: 'assessment.step.divider',
        component: () => import("../views/auth/assessment/Index.vue"),
        meta: {
            useLayout: false,
            middleware: [auth],
            auth: true
        }
    }
]