import auth from "../middleware/auth.js";
import manageOrganisations from "../middleware/manageOrganisations.js";

export default [
    {
        path: "/team",
        name: 'team.index',
        component: () => import("../views/auth/team/Show.vue"),
        meta: {
            title: 'Perspective - Dashboard',
            heading: 'headings.team',
            middleware: [auth],
            auth: true
        }
    },
    {
        path: "/teams/:id",
        name: 'teams.show',
        component: () => import("../views/auth/organisations/teams/Show.vue"),
        meta: {
            heading: 'headings.team',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    },
    {
        path: "/teams/:id/insights",
        name: 'teams.insights',
        component: () => import("../views/auth/organisations/teams/Data.vue"),
        meta: {
            heading: 'headings.team',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    },
    {
        path: "/teams/:id/pq-scores",
        name: 'teams.pq-scores',
        component: () => import("../views/auth/organisations/teams/Scores.vue"),
        meta: {
            heading: 'headings.team',
            hideTeamName: true,
            middleware: [auth, manageOrganisations],
            auth: true
        }
    }
]
