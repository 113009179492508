<template>
  <div class="fixed inset-0 z-50 flex flex-col justify-end" v-if="consentGiven === null">
	<div class="absolute inset-0 bg-black opacity-50"></div>

	<div class="relative">
	  <div class="sm:flex sm:items-center sm:justify-between sm:max-w-7xl sm:flex-1 bg-white sm:mx-auto px-8 py-6 rounded-t-md sm:space-x-8">
		<div>
		  <h3 class="font-medium text-lg">
			{{ $t('cookie_consent.title') }}
		  </h3>
		  <p class="mb-2 sm:mb-0 mt-2">
			<i18n-t keypath="cookie_consent.description" tag="label" for="terms">
			  <template v-slot:cookie_policy>
				<a
					target="_blank"
					href="https://cdn.gainperspective.ai/Perspective-Cookie-Policy.pdf"
					class="text-blue-600 hover:text-blue-500"
				>
				  {{ $t('cookie_consent.cookie_policy_text') }}
				</a>
			  </template>
			</i18n-t>
		  </p>
		</div>
		<div class="mt-4 sm:mt-0 sm:flex-shrink-0 sm:space-y-2">
		  <div class="sm:flex sm:flex-col sm:justify-center space-y-2">
			<BaseButton
				:title="$t('cookie_consent.accept_all')"
				:modal="true"
				@click="acceptAll"
			/>
			<BaseButton
				:title="$t('cookie_consent.essential_only')"
				:modal="true"
				colour="secondary"
				@click="essentialOnly"
			/>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script>
import BaseModal from "./lib/BaseModal.vue";
import BaseButton from "./lib/input/BaseButton.vue";
import Hotjar from '@hotjar/browser';

export default {
  components: {
	BaseButton,
	BaseModal,
  },
  data() {
	return {
	  consentGiven: null
	}
  },
  methods: {
	acceptAll() {
	  this.consentGiven = true;
	  localStorage.setItem('consentGiven', 'true');
	  this.loadHotjar();
	},
	essentialOnly() {
	  this.consentGiven = false;
	  localStorage.setItem('consentGiven', 'false');
	},
	loadHotjar() {
	  // Check if consent was previously given
	  const marketingJsEnabled = import.meta.env.VITE_MARKETING_JS_ENABLED;

	  if (marketingJsEnabled === 'true') {
		if (this.consentGiven) {
		  Hotjar.init(4979772, 6);
		}
	  } else {
		console.log('Non-essential tracking scripts disabled in config')
	  }
	},
  },
  mounted() {
	const localStorageConsentGiven = localStorage.getItem('consentGiven');

	if (localStorageConsentGiven === null) {
	  // We have not asked the question
	  this.consentGiven = null;
	} else if (localStorageConsentGiven === 'true') {
	  // We have asked the question and consent was given
	  this.consentGiven = true;
	  this.loadHotjar();
	} else if (localStorageConsentGiven === 'false') {
	  // We have asked the question and consent was not given
	  this.consentGiven = false;
	}
  },
};
</script>