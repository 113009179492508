<template>
  <div class="space-y-4">
	<h2 class="text-xl font-medium">{{ $t('pages.landing.slide_four.title') }}</h2>

	<div class="py-4">
	  <img :src="asset('img/12.1.svg')" class="w-1/3 mx-auto"/>
	</div>

	<p class="text-lg">{{ $t('pages.landing.slide_four.body_1') }}</p>
	<p class="text-lg">{{ $t('pages.landing.slide_four.body_2') }}</p>
  </div>
</template>

<script>
import SlideFooter from "../SlideFooter.vue";

export default {
  components: {
	SlideFooter
  }
}
</script>
