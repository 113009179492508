import axios from 'axios';
import {createApp} from 'vue'
import router from "./router";
import {createPinia} from "pinia";
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import Vapor from "laravel-vapor"
import VueApexCharts from "vue3-apexcharts";
import i18nInstance, {i18n} from './i18n.js'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import App from './App.vue'
import apiClient from "./api/base";
import {useToastStore} from "./store/toast.js";
import * as Sentry from "@sentry/vue";

const pinia = createPinia()

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp(App);

if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: "https://ccc77020fe2b4068b9a723e54c26fdd2@o4504207480717312.ingest.us.sentry.io/4504207483207680",
        integrations: [
            Sentry.browserTracingIntegration({router})
        ],
        tracesSampleRate: 0.8,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/]
    });
}

app
    .use(i18nInstance)
    .use(pinia)
    .use(router)
    .use(autoAnimatePlugin)
    .use(VueApexCharts)
    .use(FloatingVue)
    .mixin({
        methods: {
            asset: Vapor.asset
        }
    })
    .mount("#app")

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

const toastStore = useToastStore();

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 419 || error.response.status === 401) {
            toastStore.error(
                i18n.t('common.errors.invalid_token')
            );
        }

        return Promise.reject(error);
    }
);