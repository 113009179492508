import AssessmentRequest from "./AssessmentRequest.js";
import Organisation from "./Organisation.js";

export default class Team {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.last_assessment_request = data.last_assessment_request ? new AssessmentRequest(data.last_assessment_request) : null;
        this.users_active_count = data.users_active_count;
        this.challenge_state = data.challenge_state;
        this.challenge_created_by = data.challenge_created_by;
        this.challenge_voted_by = data.challenge_voted_by;
        this.users_submitted_count = data.users_submitted_count;
        this.users_voted_count = data.users_voted_count;
        this.draft_challenges_count = data.draft_challenges_count;
        this.challenge_votes_current_count = data.challenge_votes_current_count;
        this.latest_prioritised_challenge = data.latest_prioritised_challenge;
        this.has_prioritised_challenge = data.has_prioritised_challenge;
        this.has_prioritised_challenge_meeting_started = data.has_prioritised_challenge_meeting_started;
        this.permissions = data.user_permissions;
        this.challenges_count = data.challenges_count;
        this.organisation = data.organisation ? new Organisation(data.organisation) : null;
        this.is_active = data.is_active;
        this.locale = data.locale;
        this.locale_formatted = data.locale_formatted;
        this.country = data.country;
        this.work_model = data.work_model;
        this.team_function = data.team_function;
        this.organisation_id = data.organisation_id;
        this.timezone = data.timezone;
        this.created_at = data.created_at;
    }

    calculateLastAssessmentProgress() {
        if (!this.last_assessment_request) {
            return 0;
        }

        if (this.users_active_count === null || this.users_active_count === 0 || this.last_assessment_request.assessments_count === 0) {
            return 0
        }

        return Math.round(this.last_assessment_request.assessments_count / this.users_active_count * 100);
    }

    calculateLastChallengeProgress() {
        if (this.users_active_count === null || this.users_active_count === 0) {
            return 0
        }

        return Math.round(this.users_submitted_count / this.users_active_count * 100);
    }

    calculateVotingProgress() {
        if (this.users_active_count === null || this.users_active_count === 0) {
            return 0
        }

        return Math.round(this.users_voted_count / this.users_active_count * 100);
    }

    challengeInactive() {
        return this.challenge_state === 'inactive';
    }

    isChallengeCreation() {
        return this.challenge_state === 'creation';
    }

    isVoting() {
        return this.challenge_state === 'voting';
    }

    hasVotes() {
        return this.challenge_votes_current_count > 0;
    }

    hasPrioritisedChallenge() {
        return this.has_prioritised_challenge;
    }

    hasPrioritisedChallengeMeetingStarted() {
        return this.has_prioritised_challenge_meeting_started;
    }

    formatName() {
        return this.name.replace('Team', '').replace('team', '').trim();
    }

    active() {
        return this.is_active;
    }

    inactive() {
        return !this.active();
    }
}