import {createI18n} from 'vue-i18n';
import en from "./locales/en.json";
import it from "./locales/it.json";
import fr from "./locales/fr.json";

const instance = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        it,
        fr
    }
});

export default instance;

export const i18n = instance.global;